export const light = {
  toggleBgColor: "rgb(55,55,55)",
  toggleColor: "rgb(255,255,255)",
  toggleBorderColor: "1px solid rgb(44, 45, 51)",
  bgColor: "rgba(255,255,255, 0.63)",
  textColor: "rgb(0,0,0)",
  borderColor: "1px solid rgb(234, 234, 234)",
  logoColor: "rgb(0,0,0)",
  tabColor: "rgb(0,0,0)",
  tabActiveColor: "rgb(0,123,255)",
  tabActiveTextColor: "rgb(255,255,255)",
  tabNotActiveColor: "rgb(224, 224, 224)",
  tabHoverColor: "rgba(224, 224, 224)",
  tabNotHoverColor: "rgb(0, 86, 179)",
};

export const dark = {
  toggleBgColor: "rgb(255,255,255)",
  toggleColor: "rgb(49, 48, 46)",
  toggleBorderColor: "1px solid rgb(234, 234, 234)",
  bgColor: "rgba(30,30,34, 0.86)",
  textColor: "rgb(220,220,220)",
  logoColor: "rgb(220,220,220)",
  borderColor: "1px solid rgb(44, 45, 51)",
  tabColor: "rgb(205,205,205)",
  tabActiveColor: "rgb(109,109,109)",
  tabActiveTextColor: "rgb(255,255,255)",
  tabHoverColor: "rgba(255,255,255, 0.15)",
  tabNotHoverColor: "rgba(255,255,255, 0.1)",
};

export const theme = {
  light,
  dark,
};
